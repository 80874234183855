<template lang="pug">
div(
    ref="mapContainer"
    style="width: 100%; height: 270px"
)
</template>

<script>
import { ref, onMounted } from "vue"
import mapboxgl from "mapbox-gl"
import { useState } from "@/composables/state"
export default {
    setup() {
        const tempPosition = ref()

        const mapContainer = ref(0)

        const { sampleMapData } = useState()

        function initMap() {
            const map = new mapboxgl.Map({
                container: mapContainer.value,
                style: "mapbox://styles/mapbox/streets-v11",
                center: [sampleMapData.lng, sampleMapData.lat],
                zoom: 13
            })
            var marker = new mapboxgl.Marker()
                .setLngLat([sampleMapData.lng, sampleMapData.lat])
                .addTo(map)
            map.on("load", function () {
                map.resize()
            })
        }

        onMounted(() => {
            mapboxgl.accessToken =
                "pk.eyJ1IjoibWF0ZXVzemtvcmRlayIsImEiOiJja2N3MDJxODMwOWJ2MnpycGNqMXN2Y2liIn0.PK7tLTCqiTp9_AsgIO9mGw"
            window.setTimeout(() => {
                initMap()
            }, 10)
        })

        return { tempPosition, mapContainer }
    }
}
</script>