<template lang="pug">
BaseLayout
    Step1(
        :order="currentOrder"
    )
</template>

<script>
import Step1 from "@/components/sections/order-processing/Step1"
import { useUtilities } from "@/composables/utilities"
import { watch, computed } from "vue"

export default {
    components: {
        Step1
    },

    setup() {
        const { currentOrder } = useUtilities()
        return { currentOrder }
    }
}
</script>