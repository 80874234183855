<template lang="pug">
div(class="contact-boxes")
    Spacer(
        small
    )
    ExpansionPanel(
        v-model="expansionPanelOpened"
    )
        IonRow(class="receding")
            IonCol(
                size="6"
                class="d-flex flex-column"
            )
                IonText(
                    color="tertiary"
                )
                    h3(class="with-margin") Einsatzort

                Box(
                    style="background-color: var(--ion-color-accent)"
                )
                    div(class="mb-2") Frau Karin Weimann
                    div Wielandstrasse 13
                    div 70130 Stuttgart

                IonButton(
                    color="secondary"
                    expand="block"
                    class="mt-3"
                    size="large"
                )
                    IonIcon(
                        :icon="call"
                        slot="start"
                        color="white"
                        style="font-size: 16px"
                    )
                    IonText(
                        color="white"
                    )
                        h3 Anrufen
            IonCol(
                size="6"
                class="d-flex flex-column"
            )
                IonText(
                    color="tertiary"
                )
                    h3(class="with-margin") Kontakt vor Ort
                Box(
                    style="background-color: var(--ion-color-accent)"
                )
                    div(class="mb-2") Frau Karin Weimann
                    div Wielandstrasse 13
                    div 70130 Stuttgart

                IonButton(
                    color="secondary"
                    expand="block"
                    class="mt-3"
                    size="large"
                    @click="phoneModal = true"
                )
                    IonIcon(
                        :icon="call"
                        slot="start"
                        color="white"
                        style="font-size: 16px"
                    )
                    IonText(
                        color="white"
                    )
                        h3 Anrufen

                    IonIcon(
                        :icon="chevronDownCircle"
                        slot="end"
                        color="white"
                        style="font-size: 16px"
                    )
CustomModal(
    v-model="phoneModal"
)
    PhoneModalContent(
        @close="phoneModal = false"
    )
</template>

<script>
import { call, chevronDownCircle } from "ionicons/icons"
import CustomModal from "@/components/molecules/modals/CustomModal"
import PhoneModalContent from "@/components/molecules/modals/PhoneModalContent"
import ExpansionPanel from "@/components/molecules/ExpansionPanel"

import Box from "@/components/molecules/Box"
import { ref } from "vue"
export default {
    components: { Box, CustomModal, PhoneModalContent, ExpansionPanel },
    props: {
        order: {
            type: Object,
            required: true
        }
    },

    setup() {
        chevronDownCircle
        call

        const expansionPanelOpened = ref(false)

        const phoneModal = ref(false)
        return { call, chevronDownCircle, phoneModal, expansionPanelOpened }
    }
}
</script>