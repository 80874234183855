<template lang="pug">
IonCard(
    color="accent"
    class="phone-modal-content pb-5 pt-5 pl-5 pr-5 d-flex align-center justify-center flex-column relative"
    style="width: 100%; overflow: visible"
)
    IonText(
        color="tertiary"
        class="mb-4"
    )
        h1 Anrufoptionen
    IonRow(
        class="receding"
        style="width: 100%"
    )
        IonCol(
            size="12"
            class="pb-0"
        )
            IonButton(
                color="secondary"
                expand="block"
                size="large"
            )
                IonIcon(
                    :icon="call"
                    slot="start"
                    color="white"
                    style="font-size: 16px"
                )
                IonText(
                    color="white"
                )
                    h3 Festnetz
        IonCol(
            size="12"
        )
            IonButton(
                color="secondary"
                expand="block"
                size="large"
            )
                IonIcon(
                    :icon="call"
                    slot="start"
                    color="white"
                    style="font-size: 16px"
                )
                IonText(
                    color="white"
                )
                    h3 Handy

    IonButton(
        class="d-flex align-center justify-center close-btn round-btn"
        color="white"
        @click="$emit('close')"
    )
        IonIcon(
            :icon="close"
            color="accent"
            style="font-size: 22px"
        )
</template>

<script>
import { call, close } from "ionicons/icons"
export default {
    setup() {
        call
        close
        return { call, close }
    }
}
</script>
<style lang="sass" scoped>
.phone-modal-content
    .close-btn
        // background-color: white
        border: 4px solid var(--ion-color-accent)
        width: 42px
        height: 42px
        position: absolute
        right: 20px
        top: -20px
        border-radius: 50%
        // >div
        //     width
</style>