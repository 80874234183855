<template lang="pug">
div(
    class="step-1"
    v-if="!!order"
)
    //- name bar
    TopBar
        OrderName(
            :order="order"
        )

    //- contact
    ContactBoxes(
        :order="order"
    )

    //- tabs
    div
        Spacer(
            small
        )
        Container
            div(class="d-flex align-center")
                IonSegment(
                    v-model="tab"
                    :scrollable="true"
                    style="flex-grow: 1"
                )
                    IonSegmentButton(
                        value="cause"
                    )
                        IonLabel(
                            color="white"
                        ) Auftragsgrund
                    IonSegmentButton(
                        value="remarks"
                    )
                        IonLabel(
                            color="white"
                        ) Anmerkungen

                div(
                    style="margin-left: 15px"
                )
                    IonButton(
                        class="round-btn"
                        color="secondary"
                        style="width: 30px; height: 30px"
                        @click="openEditRemarksModal"
                    )
                        CustomIcon(
                            fileName="edit.png"
                        )

        SegmentCard(
            style="background-color: var(--ion-color-primary-shade)"
        )
            div(
                v-if="tab == 'cause'"
            ) {{ order.cause }}

            div(
                v-if="tab == 'remarks'"
            )
                div {{ order.remarks }}
                NoUploads(
                    v-if="!order.remarks"
                )
                    div Keine Anmerkungen

    //- map
    div
        Spacer
        Container
            MapModule

    //- buttons
    div(class="buttons-height-2")
    //-     div(class="page-buttons-wrapper")
    //-         Container
    //-             IonRow(class="receding btn-container")
    //-                 IonCol(
    //-                     size="6"
    //-                     class="pb-0"
    //-                 )
    //-                     IonButton(
    //-                         color="secondary"
    //-                         size="large"
    //-                         expand="block"
    //-                     )
    //-                         IonText(
    //-                             color="primary"
    //-                         )
    //-                             h3(class="text-center") Karte starten

    //-                 IonCol(
    //-                     size="6"
    //-                     class="pb-0"
    //-                 )
    //-                     IonButton(
    //-                         color="secondary"
    //-                         size="large"
    //-                         expand="block"
    //-                     )
    //-                         IonText(
    //-                             color="primary"
    //-                         )
    //-                             h3 Ablehnen

    //-                 IonCol(
    //-                     size="6"
    //-                 )
    //-                     IonButton(
    //-                         color="secondary"
    //-                         size="large"
    //-                         expand="block"
    //-                     )
    //-                         IonText(
    //-                             color="primary"
    //-                         )
    //-                             h3 Verspätet

    //-                 IonCol(
    //-                     size="6"
    //-                 )
    //-                     IonButton(
    //-                         color="secondary"
    //-                         size="large"
    //-                         expand="block"
    //-                         @click="$router.push({ name: 'order-2', params: { id: order.id } })"
    //-                     )
    //-                         IonText(
    //-                             color="primary"
    //-                         )
    //-                             h3 Angekommen

    CustomModal(
        v-model="editRemarksModal"
    )
        ConfirmModalContentWithSlot(
            @confirm="updateRemarks"
            @decline="closeEditRemarksModal"
            secondVariant
        )
            div(
                class="d-flex flex-column"
                style="height: 100%"
            )
                Spacer
                IonTextarea(
                    style="flex-grow: 1; --background: white; color: black"
                    v-model="editedText"
                    class="radius"
                )
</template>

<script>
import TopBar from "@/components/sections/TopBar"
import ContactBoxes from "@/components/sections/ContactBoxes"
import OrderName from "@/components/molecules/OrderName"
import SegmentCard from "@/components/molecules/SegmentCard"
import CustomIcon from "@/components/molecules/CustomIcon"
import CustomModal from "@/components/molecules/modals/CustomModal"
import ConfirmModalContentWithSlot from "@/components/molecules/modals/ConfirmModalContentWithSlot"
import NoUploads from "@/components/molecules/camera-uploads/NoUploads"
import MapModule from "@/components/molecules/MapModule"

import { ref, getCurrentInstance, watch } from "vue"
import { useState } from "@/composables/state.js"
import { useUtilities } from "@/composables/utilities"

export default {
    components: {
        TopBar,
        OrderName,
        ContactBoxes,
        SegmentCard,
        CustomIcon,
        ConfirmModalContentWithSlot,
        CustomModal,
        NoUploads,
        MapModule
    },
    props: {
        order: {
            required: true
        }
    },
    setup(props) {
        const tab = ref("cause")

        // bus
        const emitter = useUtilities().getEmitter()

        // remarks edit
        const editedText = ref(null)
        const editRemarksModal = ref(false)

        const { updateOrder } = useState()

        function openEditRemarksModal() {
            editRemarksModal.value = true
            editedText.value = JSON.parse(JSON.stringify(props.order.remarks))
        }

        function closeEditRemarksModal() {
            editRemarksModal.value = false
            editedText.value = null
        }

        async function updateRemarks() {
            emitter.emit("setLoadingModal", true)
            const orderCopy = JSON.parse(JSON.stringify(props.order))
            orderCopy.remarks = editedText.value
            await updateOrder(orderCopy)
            setTimeout(() => {
                emitter.emit("setLoadingModal", false)
            }, 1000)
            setTimeout(() => {
                emitter.emit("showSuccessModal")
            }, 1000)
            closeEditRemarksModal()
        }

        return {
            tab,
            editRemarksModal,
            updateRemarks,
            editedText,
            openEditRemarksModal,
            closeEditRemarksModal
        }
    }
}
</script>

<style lang="sass" scoped>
.step-1
    .btn-container
        ion-col
            padding-top: 0
            padding-bottom: 0
</style>